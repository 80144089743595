<template>
  <div class="qrcode">
    <div id="reader"></div>
    <div id="close" class="" v-show="showBtn">
      <div class="btn-close"  @click.stop="close">关闭</div>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";
export default {
  data(){
    return{
      showBtn:false
    }
  },
  created() {
    this.getCameras();
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    getCameras() {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            this.html5QrCode = new Html5Qrcode("reader");
            
            setTimeout(() => {
              this.showBtn=true
            }, 500);
            this.start();
          }
        })
        .catch((err) => {
          console.error(err)
          // handle err
          this.html5QrCode = new Html5Qrcode("reader");
          this.error = "您需要授予相机访问权限";
          this.$emit("err", this.error);
        });
    },
    start() {
      //environment后置 user前置
      this.html5QrCode
        .start(
          { facingMode: "environment" },
          {
            fps: 2,
            qrbox: { width: 250, height: 250 },
          },
          (decodedText, decodedResult) => {
            this.$emit("ok", decodedText);
          }
        )
        .catch((err) => {
          this.$emit("err", err);
        });
    },
    stop() {
      this.html5QrCode
        .stop()
        .then((ignore) => {
          // QR Code scanning is stopped.
          console.log("QR Code scanning stopped.");
        })
        .catch((err) => {
          // Stop failed, handle it.
          console.log("Unable to stop scanning.");
        });
    },
    close() {
      this.stop();
      this.$emit("close");
    }
  },
};
</script>

<style lang="less" scoped>
.qrcode {
  position: fixed;
  height: 100%;
  width: 100%;
  // background: rgba(0,0,0, 0.48);
  top: 0;
  left: 0;
  z-index: 100;
}
#reader {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#close{
 
  position: fixed;
  width: 100%;
  bottom: 50px;
  display: flex;
  justify-content: center;
  .btn-close{
    width: 100px;
    height: 40px;
    background: rgb(241, 241, 241);
    border-radius: 7px;
    text-align: center;
    line-height: 40px;
    size: 30px;
    font-weight: bold;
  }
}
</style>
