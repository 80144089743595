<template>
  <div class="container">
    <div class="pa-20">
      <img class="logo" src="~@/assets/img/logo.png" @click="reload()" />
      <div class="search flex f-center">
        <el-select
          v-model="cardType"
          placeholder="来访类型"
          class="select ma-r-20"
        >
          <el-option
            v-for="item in cardTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          class="input"
          size="large"
          v-model="cardNo"
          label=""
          placeholder="输入访客证号码搜索"
        />
        <van-button icon="search" type="primary" color="#003AA9" @click="search"
          >搜索</van-button
        >
        <div class="fon-20 font-bold btn-anomaly" @click="loadDataAbnormal()">
          异常预约单
        </div>
      </div>
      <div class="content flex">
        <div class="box box1">
          <div class="titles">今日预约总人数</div>
          <div class="num">{{ detail.countTotal }}</div>
        </div>
        <div class="box box2">
          <div class="titles">今日待访问人数</div>
          <div class="num">{{ detail.countTodo }}</div>
        </div>
        <div class="box box3">
          <div class="titles">当前园区内访客人数</div>
          <div class="num">{{ detail.countIn }}</div>
        </div>
        <div class="box box4">
          <div class="titles">今日已访问人数</div>
          <div class="num">{{ detail.countDone }}</div>
        </div>
        <div class="box box5">
          <div class="titles">厂务维护</div>
          <div class="num">{{ detail.countStaff }}</div>
        </div>
      </div>
      <div class="flex f-around width80 ma-lr-auto">
        <div class="exit btn" @click="enter">扫码识别访客码</div>
        <div class="green btn" @click="$router.push('/subscribe')">
          查看预约单
        </div>
        <div class="setting btn" @click="$router.push('/setting')">设置</div>
      </div>
    </div>

    <el-dialog title="异常预约单" v-model="dialogTableVisible">
      <el-table :data="gridData" height="250" style="width: 100%" size="large">
        <el-table-column type="index" width="50" />
        <el-table-column
          property="type"
          label="预约类型"
          width="100"
        ></el-table-column>
        <el-table-column
          property="name"
          label="来访人/负责人姓名"
          width="150"
        ></el-table-column>
        <el-table-column
          property="phone"
          label="来访人/负责人手机号"
          width="160"
        ></el-table-column>
        <el-table-column property="abReason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>

    <BarScan
      v-if="show"
      ref="qrcode"
      @ok="getResult"
      @err="getError"
      @close="show = false"
    ></BarScan>
  </div>
</template>

<script>
import BarScan from "@/components/Qrcode.vue";
let _this;
export default {
  components: {
    BarScan,
  },
  data() {
    return {
      dialogTableVisible: false,
      gridData: [],
      detail: {
        countTotal: 0,
        countTodo: 0,
        countIn: 0,
        countDone: 0,
      },
      cardNo: "",
      show: false,
      setInterval: null,
      cardType: "",
      cardTypeList: [
        //(0=客户,1=承包商,2=面试,3=临时访客卡)
        { name: "客户", id: 0 },
        { name: "承包商", id: 1 },
        { name: "面试", id: 2 },
        { name: "临时访客卡", id: 3 },
        { name: "外来供应商", id: 4 },
        { name: "关联公司人员", id: 5 },
      ],
    };
  },
  created() {
    _this = this;
    _this.loadData();
    _this.setInterval = setInterval(() => {
      _this.getCode();
    }, 2 * 1000);

    //url带参，模拟扫码，测试代码
    console.log(_this.$route.query.id);
    if (_this.$route.query.id) {
      _this.getResult(_this.$route.query.id);
    }
  },
  //监听当前页面离开事件
  beforeRouteLeave(to, from, next) {
    console.log("离开");
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    if (_this.$refs.qrcode) {
      _this.$refs.qrcode.stop();
    }
    clearInterval(_this.setInterval);
    next();
  },
  methods: {
    //刷新当前页面
    reload(){
      window.location.reload()
    },
    // 加载数据
    loadData() {
      _this
        .$request({
          url: "/security/home",
          method: "GET",
        })
        .then((result) => {
          _this.detail = result;
        });
    },
    //获取异常数据列表
    loadDataAbnormal() {
      _this
        .$request({
          url: "/security/abnormal",
          method: "GET",
        })
        .then((result) => {
          _this.gridData = result.map((item) => {
            //类型(0=个人预约,1=5人以上预约)
            if (item.type == 0) {
              item.type = "个人预约";
            } else if (item.type == 1) {
              item.type = "5人以上预约";
            }
            return item;
          });
          _this.dialogTableVisible = true;
        });
    },
    // 搜索判断几人预约
    search() {
      if (_this.$validator.isEmpty(_this.cardNo)) {
        return _this.$toast("请输入访客证号");
      }
      _this
        .$request({
          url: "/security/query",
          method: "GET",
          params: {
            cardNo: _this.cardNo,
            cardType: _this.cardType,
            queryType: 1,
          },
        })
        .then((res) => {
          //0 个人预约， 1 多人预约
          if (res.type == 0) {
            if (res.inPark == 0) {
              return _this.$toast("该访客证已离厂");
              //未入场
              // _this.$router.replace({
              //   path: "/enter/personal",
              //   query: { id: res.id },
              // });
            } else if (res.inPark == 1) {
              _this.$router.replace({
                path: "/leave/personal",
                query: { cardNo: _this.cardNo ,cardType: _this.cardType},
              });
            }
          } else if (res.type == 1) {
            //多人预约，入场还是离场根据状态来选择
            //入厂状态(0=离厂,1=入厂)
            if (res.members[0].inPark == 0) {
              _this.$router.replace({
                path: "/enter/multiple",
                query: { cardNo: _this.cardNo, cardType: _this.cardType },
              });
            } else if (res.members[0].inPark == 1) {
              _this.$router.replace({
                path: "/leave/multiple",
                query: { cardNo: _this.cardNo, cardType: _this.cardType },
              });
            }
          }
        });
    },

    // 入场
    enter() {
      if (_this.$refs.qrcode) {
        if (_this.$refs.qrcode.error) {
          return _this.$toast(_this.$refs.qrcode.error);
        }
      }
      _this.show = true;
    },

    // 扫码结果
    getResult(result) {
      //获取到了值，显示弹框，停止轮询。弹框消失，继续轮询。
      clearInterval(_this.setInterval);
      _this.$redirectTo(result).catch((action) => {
        console.log("关闭");
        console.log(action);
        if (action == "常驻人口") {
          _this.loadData();
        }
        //继续循轮询
        _this.setInterval = setInterval(() => {
          _this.getCode();
        }, 2 * 1000);
      });
    },

    // 扫码失败
    getError(e) {
      _this.show = false;
      _this.$toast(e);
    },

    //轮询获取扫码枪
    getCode() {
      _this
        .$request({
          url: "/security/code",
          method: "GET",
          params: {
            eid: _this.$store.state.entranceId,
          },
        })
        .then((result) => {
          if (result) {
            _this.getResult(result);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.btn-anomaly {
  position: absolute;
  right: 0;
  right: 30px;
  color: red;
}

.content {
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  .box {
    width: 48%;
    height: 150px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .titles {
      font-size: 24px;
      font-weight: bold;
    }

    .num {
      font-size: 50px;
      font-weight: bold;
      color: #f18100;
      margin-top: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 5px;
      width: 99%;
      left: 50%;
      border-radius: 3px;
      transform: translateX(-50%);
      background: rgba(241, 129, 0, 0.6);
    }

    &.box2 {
      &::before {
        background: rgba(0, 58, 169, 0.6);
      }

      .num {
        color: #002eff;
      }
    }

    &.box3 {
      width: 30%;
      &::before {
        background: rgba(78, 153, 0, 0.6);
      }

      .num {
        color: #4e9900;
      }
    }

    &.box4 {
      width: 30%;
      &::before {
        background: rgba(143, 0, 152, 0.6);
      }

      .num {
        color: #8f0098;
      }
    }

    &.box5 {
      width: 30%;
      &::before {
        background: rgba(0, 152, 152, 0.6);
      }

      .num {
        color: rgba(0, 152, 152, 0.6);
      }
    }
  }
}

.btn {
  width: 300px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
}

.exit {
  background: #ff0000;
}

.green {
  background: #4e9900;
}
.search {
  margin-top: 50px;
  width: 900px;
}

.setting {
  background: #00a0e9;
}

.search .van-button {
  height: 50px;
  width: 120px;
}

.search .select {
  height: 50px;
}
/deep/.search .select .el-input__inner {
  height: 50px;
  line-height: 50px;
  background: #eeeeee;
  // font-size: 18px;
}
/deep/.search .input {
  padding: 0;
}
/deep/.search .input .el-input__inner {
  height: 50px;
  line-height: 50px;
  background: #eeeeee;
  // font-size: 16px;
}
/deep/.search .select .el-input {
  line-height: 50px;
  // font-size: 18px;
}
/deep/.search .select .el-input__suffix {
  line-height: 50px;
}
/deep/input::-webkit-input-placeholder {
  /* placeholder字体大小 */
  //  font-size: 18px;
}
</style>